import React from 'react'
import { Loader2Icon, AlertCircle } from 'lucide-react'

const Error = ({ message }: { message: string }) => {
    return (
        <div className='h-full w-full absolute opacity-70 z-50 top-0 left-0 flex justify-center items-center bg-slate-800 flex-col'>
            <AlertCircle size={44} color="#ff0000" strokeWidth={2.75} absoluteStrokeWidth />
            <div className='font-bold text-3xl text-white mt-2'>{message}</div>
            
        </div>
    )
}

export default Error