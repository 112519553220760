
import React from 'react'
import { AuthProvider } from './AuthContext';

// Use the AppProps type from 'next/app' to type the props correctly.
function Provider({
    children,
}: Readonly<{
    children: React.ReactNode;
}>) {


    return (
        <AuthProvider>
            {children}
        </AuthProvider>
    );
}

export default Provider;