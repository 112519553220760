import LOGO from './assets/elite logo.png';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ArrowUp, Loader2Icon, LoaderIcon } from 'lucide-react';
import Bubble from './Bubble';
// import { useSession } from 'next-auth/react';
// import { signIn } from 'next-auth/react';
import axiosinstance, { BASE_URL } from './axiosInterceptorInstance';
import { Button } from './button';
import { useAuth } from './AuthContext';
import TextareaWithButton from './textareabutton';
import Loading from './Loading';
import Error from './Error';
import { toast } from 'react-toastify';

const contentGatheringData = `an agent will gather the data you need from the Database`;
export default function Home() {
  const { user, customer, login, isLoadingLogin, isLoggedIn, error } = useAuth(); // Use custom hook

  const [message, setMessage] = useState('');
  const [items, setItems] = useState<Array<{ role: string; prompt: string }>>([]);
  const [sidechat, setSidechat] = useState(false);
  const [customers, setCustomers] = useState<any[]>([]);
  const [isUserSetupPending, setIsUserSetupPending] = useState(false);
  const [history, setHistory] = useState<any[]>([]);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onSend();
    }

    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault();
      setMessage((prev) => prev + '\n');
    }
  };

  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const messagesEndRef2 = useRef<HTMLDivElement | null>(null);

  const [canAutoScroll, setCanAutoScroll] = useState(true);
  const [canAutoScroll2, setCanAutoScroll2] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [chatActive, setChatActive] = useState(true);
  const [gatheringData, setGatheringData] = useState({ loading: false });
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const sideChatContainerRef = useRef<HTMLDivElement | null>(null);

  const getCustomers = async () => {
    try {
      const response = await axiosinstance.get('/customers');
      setCustomers(response.data);
    } catch (error) {
      console.log('err', error);
    }
  };

  useEffect(() => {
    getCustomers();
  }, []);

  const getUserHistory = async () => {
    const chatHistory = await axiosinstance.get('/user_chat_history', {
      headers: {
        Authorization: `Bearer ${user.access_token}`
      }
    });

    setItems(
      chatHistory.data.map((elem: any) => ({
        role: 'K2',
        prompt: elem.content
      }))
    );
  };

  const eventSourceRef = useRef<EventSource | null>(null);


  const startStreaming = () => {
    try {
      if (user) {
        const access_token = user.access_token;

        if (access_token) {
          // const url = `https://360ai.k2view.com:5000/stream?token=${encodeURIComponent(access_token)}`;
          const url = `${BASE_URL}/stream?token=${encodeURIComponent(access_token)}`;

          eventSourceRef.current = new EventSource(url);

          eventSourceRef.current.onmessage = (event) => {
            const newMessage = JSON.parse(event.data);
            if (newMessage.content === 'setup_done') {
              setIsUserSetupPending(false);
              eventSourceRef.current?.close();
              return;
            } else if (newMessage.endStream) {
              eventSourceRef.current?.close();
              return;
            } else if (!newMessage.content) {
              return;
            }
            if (newMessage.content.includes(contentGatheringData)) {
              setGatheringData({ loading: true });
            }
            setHistory((prevMessages) => [
              ...prevMessages,
              { ...newMessage, time: new Date().toLocaleString() }
            ]);
          };

          eventSourceRef.current.onerror = (error) => {
            console.error('EventSource failed:', error);
            setIsUserSetupPending(false);
            eventSourceRef.current?.close();
          };
        }
      }
    } catch (error) {
      console.error('Error setting auth token', error);
    }
  };

  const eventSourceRef2 = useRef<EventSource | null>(null);

  const startStreaming2 = (message: string) => {
    try {
      if (user) {
        const access_token = user.access_token;

        if (access_token) {
          const url = `${BASE_URL}/message?token=${encodeURIComponent(access_token)}&message=${encodeURIComponent(message)}`;

          eventSourceRef2.current = new EventSource(url);

          eventSourceRef2.current.onmessage = (event) => {
            console.log('Message received:', event);
            const data = JSON.parse(event.data);
            let msg = data.content;
            if (data.endStream) {
              eventSourceRef2.current?.close();
            } else {
              setIsLoading(false);
              setItems((prev) => {
                const newItems = [...prev];
                if (newItems.length === 0 || newItems[newItems.length - 1].role !== 'K2') {
                  newItems.push({
                    role: 'K2',
                    prompt: msg
                  });
                } else {
                  newItems[newItems.length - 1].prompt += msg;
                }
                return newItems;
              });
            }
          };

          eventSourceRef2.current.onerror = (error) => {
            console.error('EventSource error:', error);
            if (eventSourceRef2.current?.readyState === EventSource.CLOSED) {
              // Ignore the error if the connection is already closed
              return;
            }
            setItems((prev) => [
              ...prev,
              {
                role: 'K2',
                prompt: 'Error in server'
              }
            ]);
            toast.error('Error or token expired, please start a new chat');
            setChatActive(false);
            eventSourceRef2.current?.close();
            setIsLoading(false);
          };

          eventSourceRef2.current.onopen = () => {
            console.log('EventSource opened:', eventSourceRef2.current);
            setCanAutoScroll(true);
          };
        }
      }
    } catch (error) {
      console.error('Error setting auth token', error);
    }
  };

  useEffect(() => {
    // Logic to handle component mount and unmount
    return () => {
      // Ensure to close the EventSource when the component unmounts
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      setIsUserSetupPending(true);
      getUserHistory();
      startStreaming();
    }
  }, [isLoggedIn]);

  const handleScroll = useCallback(() => {
    // Check if the chat container scroll is at the bottom
    if (chatContainerRef.current) {
      const isScrolledToBottom =
        chatContainerRef.current.scrollHeight - chatContainerRef.current.clientHeight <=
        chatContainerRef.current.scrollTop + 1;
      setCanAutoScroll(isScrolledToBottom);
    }
  }, []);

  const handleScroll2 = useCallback(() => {
    // Check if the chat container scroll is at the bottom
    if (sideChatContainerRef.current) {
      const isScrolledToBottom =
        sideChatContainerRef.current.scrollHeight - sideChatContainerRef.current.clientHeight <=
        sideChatContainerRef.current.scrollTop + 1;
      setCanAutoScroll2(isScrolledToBottom);
    }
  }, []);

  useEffect(() => {
    // Attach scroll event listener
    const chatContainer = chatContainerRef.current;
    const sideChatContainer = sideChatContainerRef.current;
    if (chatContainer) {
      chatContainer.addEventListener('scroll', handleScroll);
    }

    if (sideChatContainer) {
      sideChatContainer.addEventListener('scroll', handleScroll2);
    }

    return () => {
      // Clean up the event listener
      if (chatContainer) chatContainer.removeEventListener('scroll', handleScroll);
      if (sideChatContainer) sideChatContainer.removeEventListener('scroll', handleScroll2);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (canAutoScroll) {
      messagesEndRef.current?.scrollIntoView({
        behavior: 'instant'
      });
    }
  }, [items, canAutoScroll]);

  useEffect(() => {
    if (!chatActive) {
      messagesEndRef.current?.scrollIntoView({
        behavior: 'instant'
      });
    }
  }, [chatActive]);

  useEffect(() => {
    if (canAutoScroll2) {
      messagesEndRef2.current?.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [canAutoScroll2, history]);

  const onSend = async () => {
    if (isLoading) return;
    if (!message.trim()) return;
    if (!isLoggedIn) return;

    startStreaming();

    const save = !message.includes('#####');

    try {
      if (save) {
        setIsLoading(true);
        setItems((prev) => [
          ...prev,
          {
            role: message.includes('###') ? 'K2' : 'human',
            prompt: message
          }
        ]);
      }

      setMessage('');

      startStreaming2(message);
      // Send the message to the server
    } catch (error) {
      setItems((prev) => [
        ...prev,
        {
          role: 'K2',
          prompt: 'Error in server'
        }
      ]);

      toast.error('Error or token expired, please start a new chat');
      setChatActive(false);
    } finally {
      setIsLoading(false);
    }
  };

  const [searchQuery, setSearchQuery] = useState('');
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const customersResult = useMemo(
    () =>
      customers
        .filter(
          (customer) =>
            customer.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            customer.last_name.toLowerCase().includes(searchQuery.toLowerCase())
        )
        .sort((a, b) => {
          // Convert names to lowercase for case-insensitive comparison
          const nameA = a.first_name.toLowerCase();
          const nameB = b.first_name.toLowerCase();

          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;

          return 0; // If the first names are equal, no sorting is necessary
        }),
    [customers, searchQuery]
  );

  const [routeId, setRouteId] = useState('');

  useEffect(() => {
    // Assuming the URL is like "http://localhost:3000/2bb7fde6-a9fa-4d00-b692-25b62fc98ff9"
    // and you want to extract the UUID part
    const pathArray = window.location.pathname.split('/');
    const id = pathArray[1];
    setRouteId(id);
  }, []);

  const startNewChat = async (event) => {
    if (event.shiftKey) {
      try {
        const res = await axiosinstance.post('/newchat', {
          id: customer.customer_id
        });
        if (res.data.success) {
          window.location.reload();
        }
      } catch (err) {
        console.log('err', err);
      }
    } else {
      window.location.reload();
    }
  };

  return (
    <div className="relative w-screen h-screen flex">
      {isLoadingLogin && <Loading message="Starting Session..." />}

      {error && <Error message="Unauthorized" />}
      {!isLoggedIn && (
        <div className="fixed top-0 left-0 w-screen h-screen z-20 flex items-center justify-center inset-0 bg-black bg-opacity-80">
          <div className="bg-white p-8 rounded-lg shadow-2xl flex flex-col gap-2 justify-start items-center h-[80%] w-[80%]">
            <form className="max-w-md w-full">
              <label className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20">
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="search"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  id="default-search"
                  className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search customer"
                  required
                />
                <button
                  type="submit"
                  className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                  Search
                </button>
              </div>
            </form>

            <div className="grid lg:grid-cols-5 h-full overflow-auto scrollbarStyled">
              {customersResult.map((customer) => (
                <div
                  key={customer.customer_id}
                  className="m-2 bg-white rounded-lg shadow-xl lg:flex lg:max-w-lg w-[250px] h-[250px] transform transition duration-500 
                                hover:scale-110 cursor-pointer"
                  onClick={() => login(customer, routeId)}>
                  <div className="p-6 bg-gray-50  w-full h-full flex flex-col justify-between">
                    <div className="flex items-center gap-4">
                      <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                        <span className="font-medium text-gray-600 dark:text-gray-300">
                          {customer.first_name[0].toUpperCase()}
                          {customer.last_name[0].toUpperCase()}
                        </span>
                      </div>
                      <div className="font-medium dark:text-white flex flex-col items-start">
                        <div>
                          {customer.first_name} {customer.last_name}
                        </div>
                      </div>
                    </div>
                    <div>
                      {/* <div className='flex gap-3 items-center justify-start mt-2'>
                                                <div className={`rounded-lg ${customer.invoices.length > 0 ? "bg-red-500 animate-pulse" : "bg-green-500"} w-[10px] h-[10px]`}></div>
                                                <div className='text-gray-600 dark:text-gray-300'>invoices</div>
                                            </div>
                                            <div className='flex gap-3 items-center justify-start'>
                                                <div className={`rounded-lg ${customer.outages.length > 0 ? "bg-red-500 animate-pulse" : "bg-green-500"} w-[10px] h-[10px]`}></div>
                                                <div className='text-gray-600 dark:text-gray-300'>outages</div>
                                            </div> */}
                      <div className="flex gap-3 items-center justify-start mt-2">
                        <div className="text-gray-600 dark:text-gray-300">
                          {customer.customer_id === 1 && 'Balance , Outage '}
                          {/* {customer.customer_id === 2 && "Balance , Outage "} */}
                          {customer.customer_id === 3 && 'Outage'}
                          {customer.customer_id === 4 && 'Balance'}
                          {customer.customer_id === 5 && 'Future planned outage'}
                          {customer.customer_id === 6 && 'Closed HBO channel plan '}
                        </div>
                      </div>
                      <div className="flex gap-3 items-center justify-start mt-2">
                        <div className="overflow-auto h-[100px] mt-1 gap-2 col gap-y-2 scrollbarStyled px-1 w-full">
                          {customer.devices.map((el, i) => (
                            <div
                              className={`p-1 text-sm ${el[9] === 'Active' ? 'bg-blue-300' : 'bg-red-300'} rounded-md shadow line-clamp-1 h-[26px] w-full my-2`}
                              title={`${el[5]} ${el[3]}`}
                              key={i}>
                              {el[5]} {el[3]}
                            </div>
                          ))}
                        </div>
                      </div>
                      {/* {customer.outages.length > 0 && <div>outages</div>} */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* <div className="mb-4 w-full">
                            <input type="text" id="name" className="mt-1 p-2 w-full border-black border-2 rounded-md shadow-sm" />
                        </div> */}
            {/* <button className="px-4 py-2 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-700 w-full" onClick={login}>Start new Chat</button> */}
            {/* {isLoadingLogin && <div> <Loader2Icon className='animate-spin' /></div>} */}
          </div>
        </div>
      )}

      <div
        className={`flex flex-col flex-shrink-0 justify-center items-center ${sidechat ? 'w-[65%]' : 'w-full'}`}>
        <div className="flex">
          <div className="absolute inset-0 bg-gradient-to-b from-white via-white/95 to-blue-500 h-1/2" />
          <div className="relative z-10 max-w-2xl mx-auto flex flex-col justify-between items-center p-10 ">
            <img src={LOGO} alt={''} className="w-100 pt-8" />
            <p className=" font-bold text-black text-3xl">How can we help you today?</p>
          </div>
        </div>
        <div
          className="flex flex-col justify-between rounded-xl border bg-white shadow relative z-10 p-1 m-10 w-8/12 scrollbarStyled"
          style={{ height: 'calc(100% - 300px)' }}>
          {isUserSetupPending && <Loading message="Generating User Info..." />}
          <div className="flex flex-col pt-10 overflow-auto h-full px-20" ref={chatContainerRef}>
            {items.map((el, index) => (
              <Bubble
                key={index}
                message={{
                  role: el.role,
                  prompt: el.prompt
                }}
                mainchat={true}
              />
            ))}
            {isLoading && (
              <Bubble
                message={{
                  role: 'K2',
                  prompt: 'Loading...'
                }}
                mainchat={true}
              />
            )}
            {gatheringData.loading && (
              <div className="flex items-center ml-[40px]">
                <div role="status">
                  {/* {gatheringData.success ? <svg className="w-4 h-4 me-2 text-green-500 dark:text-green-400 flex-shrink-0 animate-appear" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg> : */}
                  <svg
                    aria-hidden="true"
                    className="w-4 h-4 me-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
                Gathering data about the customer...
              </div>
            )}

            {!chatActive && (
              <div className="bg-red-500 text-white text-center p-2 mt-2 rounded sticky bottom-0 left-0">
                The chat session has ended.
              </div>
            )}
            <div ref={messagesEndRef} style={{ width: '100%' }} />
          </div>
          <div className="flex px-20 py-4 justify-center items-center gap-3">
            <TextareaWithButton
              autoComplete="off"
              value={message}
              onKeyDown={handleKeyPress}
              onChange={handleInputChange}
              name="message"
              placeholder="Ask me anything..."
              className="w-full flex items-center resize-none overflow-hidden bg-background"
              buttonText={''}
              disabled={isLoading || !chatActive ? true : false}
              onButtonClick={onSend}>
              <div className=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 flex items-center justify-center h-full rounded cursor-pointer w-4">
                <div>
                  <ArrowUp className="w-5 h-5" />
                </div>
              </div>
            </TextareaWithButton>
          </div>
        </div>
      </div>
      <div
        ref={sideChatContainerRef}
        className={` fixed top-0 right-0 ${sidechat ? '' : 'translate-x-full'}  h-screen  w-[45%] bg-white z-10 flex-grow-0 flex-shrink-0 basis-[45%] shadow-2xl ease-in-out duration-300 from-he flex flex-col gap-3 items-start `}>
        <div className="h-10 bg-blue-100 w-full flex justify-center items-center">
          Customer: {customer.first_name} {customer.last_name}
        </div>
        <div className="h-full scrollbarStyled overflow-auto p-4">
          {history.map((el, i) => (
            <React.Fragment key={i}>
              <Bubble
                message={{
                  role: el.type,
                  prompt: el.content,
                  time: el.time,
                  prompt_history: el.prompt_history
                }}
                divider={el.type === 'human' ? true : false}
                // markdownDisabled={true}
              />
            </React.Fragment>
          ))}
        </div>

        <div ref={messagesEndRef2} style={{ width: '100%' }} />
      </div>
      <div className="text-center fixed top-2 left-2 z-10">
        <button
          onClick={() => setSidechat((prev) => !prev)}
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          type="button"
          data-drawer-target="drawer-navigation"
          data-drawer-show="drawer-navigation"
          aria-controls="drawer-navigation">
          {sidechat ? 'Hide' : 'Show'} full history
        </button>
      </div>
      <div className="text-center fixed top-2 left-[170px] z-10">
        <button
          onClick={startNewChat}
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          type="button"
          data-drawer-target="drawer-navigation"
          data-drawer-show="drawer-navigation"
          aria-controls="drawer-navigation">
          Start new chat
        </button>
      </div>
    </div>
  );
}

// export async function getServerSideProps({ params }: any) {
//   // Fetch data based on params.id
//   const res = await fetch(`http://localhost:5000/session`);
//   const session = await res.json();
//   console.log(session)
//   return {
//     session
//   };
// }
