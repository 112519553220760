// src/context/AuthContext.js
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import axiosinstance from './axiosInterceptorInstance';

const AuthContext = createContext<any>({ user: {} });

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({
  children
}: Readonly<{
  children: React.ReactNode;
}>) => {
  const [user, setUser] = useState<any>({});
  const [customer, setCustomer] = useState<any>({});
  const [error, setError] = useState('');
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [sessionType, setSessionType] = useState(null);

  const login = useCallback(async (customer) => {
    try {
      setIsLoadingLogin(true);
      const res = await axiosinstance.post('/session', { ...customer });
      const { access_token, id } = res.data;
      if (access_token && id) {
        setUser((prev) => ({ ...prev, ...{ access_token, id } }));
        setCustomer(customer);
        setError('');
      } else {
        setError('Login failed');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('Login failed');
    } finally {
      setIsLoadingLogin(false);
    }
  }, []);

  const validateGuid = useCallback(async (guid) => {
    try {
      const res = await axiosinstance.post('/validate-guid', { guid });
      const { session_type } = res.data;

      if (session_type) {
        setSessionType(session_type);
      } else {
        setError('Invalid GUID');
        setSessionType(null);
      }
    } catch (error) {
      setSessionType(null);
      setError('GUID validation error');
    }
  }, []);

  const logout = () => {
    setUser(null);
  };

  const isLoggedIn = useMemo(() => user.id && user.access_token, [user]);

  const value = {
    user,
    customer,
    error,
    login,
    logout,
    isLoadingLogin,
    isLoggedIn,
    validateGuid,
    sessionType
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
