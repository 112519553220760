import './App.css';
import React from 'react';
import Provider from './Provider';
import Page from './page';
import { BrowserRouter } from 'react-router-dom';
function App() {
  return (
    <BrowserRouter>
      <Provider>
        <div className="App">
          <Page />
        </div>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
