'use client';
import React from 'react';

interface BubbleTextProps {
  step: any;
  scrollToElement: any;
  index: any;
  setItems: any;
  getLastReflectionByIndex?: (index?: string) => void;
  getLastAnswerIndex?: (index?: string) => void;
  message_id?: string;
}

const BubbleText: React.FC<BubbleTextProps> = ({
  step,
  scrollToElement,
  index,
  setItems,
  getLastReflectionByIndex,
  getLastAnswerIndex,
  message_id
}) => {
  const targetRefInChat = {
    0: 0,
    1: 1,
    2: 1,
    3: 3,
    4: 5,
    5: 10,
    6: getLastReflectionByIndex ? getLastReflectionByIndex(message_id) : -1,
    7: getLastAnswerIndex ? getLastAnswerIndex(message_id) : -1
  };

  const handleClick = (targetStep: number, offset: number) => {
    if (step === targetStep) {
      setItems((prevItems) =>
        prevItems.map((item, idx) => {
          if (idx === index) {
            // Update the step in the specific item
            return { ...item, step: targetStep + 1 };
          }
          return item;
        })
      );
    }
    scrollToElement(targetRefInChat[targetStep], offset);
  };

  return (
    <div className="flex flex-col gap-4 text-left">
      {/* STEP 0 */}
      {step >= 0 && (
        <div className="flex gap-3 my-1 text-gray-600 text-sm w-full">
          {/* Icon */}
          <div className="w-8 h-8">
            <div className="rounded-full bg-gray-100 border p-1">
              <svg
                stroke="none"
                fill="black"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                aria-hidden="true"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"></path>
              </svg>
            </div>
          </div>

          {/* Bubble */}
          <div className="flex flex-col justify-start items-start w-full gap-2 mt-[5px] bg-[#e2f1ff] rounded-md p-4 text-left">
            <p>
              The answer you see above is based on Elite customer data integrated into the chat
              context. It is based on K2View’s powerful data layer and agentic framework.
            </p>
            <p>
              <span
                className="font-bold text-blue-600 cursor-pointer hover:underline"
                onClick={() => handleClick(0, -100)}>
                I can show and explain how it works &gt;
              </span>
            </p>
          </div>
        </div>
      )}

      {/* STEP 1 */}
      {step >= 1 && (
        <div className="flex gap-3 my-1 text-gray-600 text-sm w-full text-left">
          <div className="w-8 h-8">
            <div className="rounded-full bg-gray-100 border p-1">
              <svg
                stroke="none"
                fill="black"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                aria-hidden="true"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"></path>
              </svg>
            </div>
          </div>

          <div className="flex flex-col justify-start items-start w-full gap-2 mt-[5px] bg-[#e2f1ff] rounded-md p-4">
            <p>
              Welcome to the Trace panel, where you can see the interaction that happens behind the
              scenes between the user, K2View’s agentic framework and the GenAI LLM. The first step
              of creating Elite assistant is to setup the stage with a few prompts that give the
              agent its identity.
            </p>
            <p>
              You can see highlighted directions for the assistant persona, company details and the
              steps I should take to assist the end user.
            </p>
            <p>
              <span
                className="font-bold text-blue-600 cursor-pointer hover:underline"
                onClick={() => handleClick(1, -100)}>
                Okay, so how is the enterprise data integrated &gt;
              </span>
            </p>
          </div>
        </div>
      )}

      {/* STEP 2 */}
      {step >= 2 && (
        <div className="flex gap-3 my-1 text-gray-600 text-sm w-full">
          <div className="w-8 h-8">
            <div className="rounded-full bg-gray-100 border p-1">
              <svg
                stroke="none"
                fill="black"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                aria-hidden="true"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"></path>
              </svg>
            </div>
          </div>

          <div className="flex flex-col justify-start items-start w-full gap-2 mt-[5px] bg-[#e2f1ff] rounded-md p-4">
            <p>
              Our GenAI optimized data platform is based on a dedicated database that holds the data
              of a single Business Entity such as Customer. Since a single customer holds a small
              fraction of the overall enterprise database, we call them Micro Databases.
            </p>
            <p>
              On the right-hand side, you can see the data layer schema I am using to answer your
              questions. These are modeled in advance by automatically discovering and cataloging
              your enterprise data sources.
            </p>
            <span
              className="font-bold text-blue-600 cursor-pointer hover:underline"
              onClick={() => handleClick(2, 1300)}>
              Micro Databases as a powerful AI Semantic Layer &gt;
            </span>
          </div>
        </div>
      )}

      {/* STEP 3 */}
      {step >= 3 && (
        <div className="flex gap-3 my-1 text-gray-600 text-sm w-full">
          <div className="w-8 h-8">
            <div className="rounded-full bg-gray-100 border p-1">
              <svg
                stroke="none"
                fill="black"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                aria-hidden="true"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"></path>
              </svg>
            </div>
          </div>

          <div className="flex flex-col justify-start items-start w-full gap-2 mt-[5px] bg-[#e2f1ff] rounded-md p-4">
            <p>
              As you can see, the Micro Database schema is well annotated. It is descriptive enough
              to empower GenAI to understand the customer model. In fact Micro Databases are the
              ideal data source for GenAI
            </p>
            <ul className="list-disc list-inside ml-4">
              <li>
                <strong>Fast and Scalable</strong> – Micro Databases are small and isolated so query
                time is blazing fast.
              </li>
              <li>
                <strong>Fresh</strong> – data is always synchronized using an array of techniques
                such as Just in Time querying, push/pull, batch. Since we only need to sync the
                active micro database, there is little stress on your existing datasources.
              </li>
              <li>
                <strong>Secure</strong> – Data is contextualized by a business entity so GenAI only
                sees relevant information. The segregation between micro databases make it
                impossible for the LLM to access data outside the given customer.
              </li>
            </ul>
            <span
              className="font-bold text-blue-600 cursor-pointer hover:underline"
              onClick={() => handleClick(3, -100)}>
              How is data queried &gt;
            </span>
            <p></p>
          </div>
        </div>
      )}

      {/* STEP 4 */}
      {step >= 4 && (
        <div className="flex gap-3 my-1 text-gray-600 text-sm w-full">
          <div className="w-8 h-8">
            <div className="rounded-full bg-gray-100 border p-1">
              <svg
                stroke="none"
                fill="black"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                aria-hidden="true"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"></path>
              </svg>
            </div>
          </div>

          <div className="flex flex-col justify-start items-start w-full gap-2 mt-[5px] bg-[#e2f1ff] rounded-md p-4">
            <p>
              It is impossible to know in advance what an end user is going to ask. Instead of
              trying to anticipate every question we ask GenAI to manufacture a query that will
              provide relevant information for the user’s question. Here we see an LLM crafted query
              to return relevant data regarding outages and invoices. This is made possible by the
              micro database detailed schema layer.
            </p>
            <span
              className="font-bold text-blue-600 cursor-pointer hover:underline"
              onClick={() => handleClick(4, -100)}>
              Query Execution &gt;
            </span>
          </div>
        </div>
      )}

      {/* STEP 5 */}
      {step >= 5 && (
        <div className="flex gap-3 my-1 text-gray-600 text-sm w-full">
          <div className="w-8 h-8">
            <div className="rounded-full bg-gray-100 border p-1">
              <svg
                stroke="none"
                fill="black"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                aria-hidden="true"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"></path>
              </svg>
            </div>
          </div>

          <div className="flex flex-col justify-start items-start w-full gap-2 mt-[5px] bg-[#e2f1ff] rounded-md p-4">
            <p>
              Once the query is crafted our agentic engine executes the query and asks GenAI to
              summarize it.
            </p>
            <span
              className="font-bold text-blue-600 cursor-pointer hover:underline"
              onClick={() => handleClick(5, -100)}>
              Query Summary &gt;
            </span>
          </div>
        </div>
      )}

      {/* STEP 6 */}
      {step >= 6 && (
        <div className="flex gap-3 my-1 text-gray-600 text-sm w-full">
          <div className="w-8 h-8">
            <div className="rounded-full bg-gray-100 border p-1">
              <svg
                stroke="none"
                fill="black"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                aria-hidden="true"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"></path>
              </svg>
            </div>
          </div>

          <div className="flex flex-col justify-start items-start w-full gap-2 mt-[5px] bg-[#e2f1ff] rounded-md p-4">
            <p>
              The data is summarized and given business context that enables GenAI to use the
              information while crafting responses.
            </p>
            <span
              className="font-bold text-blue-600 cursor-pointer hover:underline"
              onClick={() => handleClick(6, -100)}>
              Reflection and chain of thought &gt;
            </span>
          </div>
        </div>
      )}

      {/* STEP 7 */}
      {step >= 7 && (
        <div className="flex gap-3 my-1 text-gray-600 text-sm w-full">
          <div className="w-8 h-8">
            <div className="rounded-full bg-gray-100 border p-1">
              <svg
                stroke="none"
                fill="black"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                aria-hidden="true"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"></path>
              </svg>
            </div>
          </div>

          <div className="flex flex-col justify-start items-start w-full gap-2 mt-[5px] bg-[#e2f1ff] rounded-md p-4">
            <p>
              At the core of the agentic framework, is the idea of a reflection loop. Given all the
              information we gathered so far we ask GenAI if we are ready to create an answer. This
              enables our Agentic Framework to break down a complex flow into smaller achievable
              steps. In this case we ask GenAI if we need additional data from the database, if we
              have all the information we needed or if we need more info from the user. The Agentic
              flow repeats this until the LLM indicates that we are ready to craft the answer.
            </p>
            <span
              className="font-bold text-blue-600 cursor-pointer hover:underline"
              onClick={() => handleClick(7, -100)}>
              Crafting the Answer &gt;
            </span>
          </div>
        </div>
      )}

      {/* STEP 8 */}
      {step >= 8 && (
        <div className="flex gap-3 my-1 text-gray-600 text-sm w-full">
          <div className="w-8 h-8">
            <div className="rounded-full bg-gray-100 border p-1">
              <svg
                stroke="none"
                fill="black"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                aria-hidden="true"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"></path>
              </svg>
            </div>
          </div>

          <div className="flex flex-col justify-start items-start w-full gap-2 mt-[5px] bg-[#e2f1ff] rounded-md p-4">
            <p>
              We are now ready to generate an answer! Given all the information we’ve gathered so
              far and the full conversation context, we ask the LLM to generate an answer to our
              question. The answer leverages LLM while grounding it with data that was dynamically
              gathered from the Micro Database.
            </p>
            <p>
              Feel free to ask a few more questions to see this mechanism in action.
              <br />
            </p>
            <span
              className="font-bold text-blue-600 cursor-pointer hover:underline"
              onClick={() => handleClick(8, -100)}>
              Impressed? &gt;
            </span>
          </div>
        </div>
      )}

      {/* STEP 9 */}
      {step >= 9 && (
        <div className="flex gap-3 my-1 text-gray-600 text-sm w-full">
          <div className="w-8 h-8">
            <div className="rounded-full bg-gray-100 border p-1">
              <svg
                stroke="none"
                fill="black"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                aria-hidden="true"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"></path>
              </svg>
            </div>
          </div>

          <div className="flex flex-col justify-start items-start w-full gap-2 mt-[5px] bg-[#e2f1ff] rounded-md p-4">
            <p>
              Thank you for taking the time to learn about our GenAI Data Platform. This is a
              glimpse into our approach to fusing enterprise data and GenAI. You’ve learned about
              key platform capabilities:
            </p>
            <ul className="list-disc list-inside ml-4">
              <div
                className="font-bold text-blue-600 cursor-pointer hover:underline"
                onClick={() => handleClick(2, 1300)}>
                Micro databases – the ideal GenAI Data Layer &gt;
              </div>
              <div
                className="font-bold text-blue-600 cursor-pointer hover:underline"
                onClick={() => handleClick(6, -100)}>
                LLM Reflection and chain of thought as a key driver of GenAI Agent &gt;
              </div>
              <div
                className="font-bold text-blue-600 cursor-pointer hover:underline"
                onClick={() => handleClick(3, -100)}>
                Automatic Data Querying &gt;
              </div>
              <div
                className="font-bold text-blue-600 cursor-pointer hover:underline"
                onClick={() => handleClick(4, -100)}>
                Turning result sets into Information &gt;
              </div>
            </ul>
            <p>
              This is an amazing platform for creating your agents, using your enterprise data
              together with GenAI, to create amazing customer experiences. You’ve seen it power
              customer chat but it can power more use cases such as Customer Sentiment and Summary,
              CRM integration, marketing campaigns, batch processes and many more.
            </p>
            <p>
              If you would like to learn more about our Agentic Data Platform and our codeless, drag
              and drop tools that make this possible, we would love to talk to you!
            </p>
            <span
              className="font-bold text-blue-600 cursor-pointer hover:underline"
              onClick={() => handleClick(8, 0)}>
              <p>
                <a
                  href="https://www.k2view.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="h-[32px] mt-[1px] rounded-[25px] border border-[#fbfbfb] bg-[#fbfbfb] flex items-center justify-center p-3 hover:ring-2 hover:border-black">
                  <span className="font-roboto text-[16px] font-medium text-center text-black ">
                    <strong>Schedule a meeting &gt;</strong>
                  </span>
                </a>
              </p>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default BubbleText;
