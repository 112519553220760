'use client';
import React, { useEffect, useState } from 'react';
import Session from './session';
import Session2 from './session2';
import { ToastContainer, toast } from 'react-toastify';
import axiosinstance, { BASE_URL } from './axiosInterceptorInstance';
import Error from './Error';

import 'react-toastify/dist/ReactToastify.css';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const Page = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login, error, user, validateGuid, sessionType } = useAuth();
  const [customers, setCustomers] = useState<any[]>([]);

  const getCustomers = async () => {
    try {
      const response = await axiosinstance.get('/customers');
      setCustomers(response.data);
    } catch (error) {
      console.log('err', error);
    }
  };

  useEffect(() => {
    getCustomers();
  }, []);

  useEffect(() => {
    const pathArray = location.pathname.split('/');
    const guid = pathArray[1];
    if (guid) {
      validateGuid(guid);
    }
  }, [validateGuid, location]);

  useEffect(() => {
    if (Array.isArray(customers) && sessionType === 2) {
      const david = customers.find((elem) => elem.customer_id === 1);

      if (david) {
        login(david);
      }
    }
  }, [customers, sessionType, login]);

  useEffect(() => {
    const savedGuid = sessionStorage.getItem('guid');
    const savedParams = sessionStorage.getItem('params');

    if (location.pathname === '/' && savedGuid) {
      // Reconstruct the relative URL
      let relativeURL = `/${savedGuid}`;
      if (savedParams) {
        const params = JSON.parse(savedParams);
        const queryString = new URLSearchParams(params).toString();
        relativeURL += `?${queryString}`;
      }

      // Navigate to the reconstructed URL
      navigate(relativeURL, { replace: true });
    }
  }, [location, navigate]);

  return (
    <React.Fragment>
      {error && <Error message="Unauthorized" />}
      {sessionType === 1 && <Session />}
      {sessionType === 2 && <Session2 />}
      {/* {sessionType === null && <Error message="404 not found" />} */}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </React.Fragment>
  );
};

export default Page;
