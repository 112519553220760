const initiationPrompt = `
Initiation Prompt

Persona
• Your name is “Elite personal helper”. You are a courteous customer service bot for “Elite Telco”, a Telecommunication company that offers internet, cable and phone services to its consumers.

Company Information
• Elite Telco’s customer service numbers:
  - Customer Support: +1 (800) 123-4567
  - Billing Support: +1 (800) 123-4568
  - Technical Support: +1 (800) 123-4569
• The company's portal is https://www.elitetelco.com, through which customers can see their usage, pay bills, and check on equipment status.

How to Resolve Issues
• When interacting with a new customer, start with an introduction message asking how you can assist the customer.
• Do not assume to know the customer issue until asked, and be assertive when you understand the issue.
• Please address the customer's issue by following these steps:
  1. Are there any outstanding invoice payments and are there active outages? If not, continue.
  2. Is there any other issue that the customer is experiencing? Look up data regarding the issue in the Micro-Database (DB). If not, continue.
  3. You can end the conversation with the customer.
• In case outstanding invoice payments exist or active outages exist, then don't proceed in helping the customer. The customer should pay their invoices, and there should be no active outage.
• Make sure that your resolution uses the steps above.
• For outstanding payments, direct to portal and/or billing support.
• Outstanding payments impact the continuity of the customer service.
• Don't assume that the customer paid their bills.
• Your answer should be only based on data gathered from the DB or in the chat.
• Your troubleshooting should be according to the customer’s devices.
• If you do not know which device has the issue, ask the customer for the device.
• Return to the customer only data related to their issue.
• Customer may have more than one subscriber.
• Do not return SQL Query to the customer.
• Do not return resolved issues to the customer.
• Your answer to the customer should relate to the issue only.
• The Customer in this chat is David Smith and CustomerID is 1.
`;

const invoiceDataRetrievalPrompt = `
Invoice data retrieval prompt 
•	You need to gather data regarding unpaid customer invoices and if there are active outages.
•	Look up for invoices with DueDate in the past.
•	Write an SQL query to get the all data needed from the above conversation.
•	Act as a database administrator, and an expert in SQL. You will be helping me write complex SQL queries.
•	Do not use UNION. Make sure to put the correct columns.
•	You should return only one SQL query.
•	Example: the SQL query is:  \`\`\`SELECT * FROM Customer\`\`\`
`

const generatedSQLQueryPrompt = `
Generated SQL Query

To gather the required data regarding unpaid customer invoices with due dates in the past and to check if there are any active outages for the customer, you can use the following SQL query:

\`\`\`sql
SELECT 
    Invoice.InvoiceID, 
    Invoice.DueDate, 
    Invoice.PaymentStatus, 
    Outages.OutageID, 
    Outages.date_time AS OutageDateTime, 
    Outages.estimated_resolution, 
    Outages.status AS OutageStatus
FROM 
    Invoice
JOIN 
    Subscriber ON Invoice.SubscriberID = Subscriber.SubscriberID
LEFT JOIN 
    Outages ON Subscriber.SubscriberID = Outages.SubscriberID
WHERE 
    Subscriber.CustomerID = 1 AND 
    Invoice.DueDate < '2024-03-12' AND 
    Invoice.PaymentStatus = 'Due' OR 
    (Outages.status = 'Active' AND Outages.date_time <= '2024-03-12')
\`\`\`

This query retrieves all necessary data regarding unpaid invoices with past due dates and checks for any active outages associated with the customer with CustomerID = 1. It joins the Invoice and Subscriber tables to ensure the invoices are for the correct customer and optionally joins the Outages table to check for any related active outages. The WHERE clause filters for invoices that are due and checks for active outages up to the current date.
`;

const customerDataSQLQueryPrompt = `
Generated SQL Query

To gather comprehensive data regarding the customer's general information, devices, and subscriptions, we can use a series of JOIN operations to combine relevant tables. Given the requirement to fetch all necessary data in a single query without using UNION, we will join the Customer, Subscriber, Device, and Invoice tables based on their relationships as defined by foreign keys. Here is the SQL query to accomplish this:

\`\`\`sql
SELECT 
    Customer.CustomerID, 
    Customer.FirstName, 
    Customer.LastName, 
    Customer.ContactInfoID, 
    Customer.AddressID, 
    Customer.DateOfBirth, 
    Customer.Gender, 
    Customer.NationalID, 
    Customer.RegistrationDate, 
    Subscriber.SubscriberID, 
    Subscriber.SubscriptionDate, 
    Subscriber.SubscriptionType, 
    Subscriber.SubscriptionName, 
    Subscriber.SubscriptionStatus, 
    Subscriber.ServiceActivationDate, 
    Subscriber.ContractStartDate, 
    Subscriber.ContractEndDate, 
    Subscriber.MonthlyFee, 
    Subscriber.DiscountCode, 
    Device.DeviceID, 
    Device.Model, 
    Device.ActivationDate, 
    Device.Manufacturer, 
    Device.IMEI, 
    Device.WarrantyEndDate, 
    Device.InstallationDate, 
    Device.Status,
    Invoice.InvoiceID, 
    Invoice.InvoiceDate, 
    Invoice.DueDate, 
    Invoice.TotalAmount, 
    Invoice.TaxAmount, 
    Invoice.DiscountAmount, 
    Invoice.PaymentStatus, 
    Invoice.PaymentDate,
    Invoice.PaymentMethod
FROM 
    Customer
INNER JOIN 
    Subscriber ON Customer.CustomerID = Subscriber.CustomerID
INNER JOIN 
    Device ON Subscriber.SubscriberID = Device.SubscriberID
INNER JOIN 
    Invoice ON Subscriber.SubscriberID = Invoice.SubscriberID
WHERE 
    Customer.CustomerID = 1;
\`\`\`

This query selects relevant columns from the Customer, Subscriber, Device, and Invoice tables for the customer with CustomerID = 1. It uses INNER JOINs to ensure that only records with matching keys in both tables are returned, ensuring that we get a comprehensive view of the customer's data across their general information, subscriptions, devices, and invoices.
`;

const reflectionPrompt = `
Please reflect on the customer's issue and the data you have, also take into consideration the steps above.

Please reflect on the following questions:
1. Do you want to gather data from the DB to help the customer with his issue (gather data only from the DB)? If not, continue.
2. Do you have all the data gathered to help the customer with his issue/request? If not, continue.
3. Do you need to ask the customer for more data? If not, continue.
4. Did the customer finish the conversation?

At the end of the reflection you should return:
{111} You need more data from DB (this is the default).
{222} You have all the data need to help the customer with their issue.
{333} You need more data from the customer.
{444} Customer finished the conversation.

Your answer should only be one of {111} {222} {333} {444}.
For example: {111}.
`;

export const tableSchema = `This is the SQL DB schema:
'TABLE NAME: customers
TABLE TECHNICAL NAME: Customer
TABLE DESCRIPTION: Customers of the company
CREATE TABLE Customer (
CustomerID INTEGER PRIMARY KEY,
FirstName TEXT,
LastName TEXT,
ContactInfoID INTEGER REFERENCES ContactInformation(ContactInfoID),
AddressID INTEGER REFERENCES Address(AddressID),
DateOfBirth DATE,
Gender TEXT,
NationalID TEXT,
RegistrationDate DATE
);

TABLE NAME: Customer Subscriber
TABLE TECHNICAL NAME: Subscriber
TABLE DESCRIPTION: All Customer Subscriber.
ContractStartDate When the contract started
ContractEndDate when contract ended.
CREATE TABLE Subscriber (
SubscriberID INTEGER PRIMARY KEY,
CustomerID INTEGER REFERENCES Customer(CustomerID),
SubscriptionDate DATE,
SubscriptionType TEXT,
SubscriptionName TEXT,
SubscriptionStatus TEXT,
ServiceActivationDate DATE,
ContractStartDate DATE,
ContractEndDate DATE,
MonthlyFee REAL,
DiscountCode TEXT
);

TABLE NAME: Customer Subscriber Devices
TABLE TECHNICAL NAME: Device
TABLE DESCRIPTION: Devices Subscribed to Customers
Model: Device Model
Manufacturer: Device Manufacturer
IMEI: Device International Mobile Equipment Identity
CREATE TABLE Device (
DeviceID INTEGER PRIMARY KEY,
SubscriberID INTEGER REFERENCES Subscriber(SubscriberID),
DeviceModelID INTEGER,
Model TEXT,
ActivationDate DATE,
Manufacturer TEXT,
IMEI TEXT,
WarrantyEndDate DATE,
InstallationDate DATE,
Status TEXT
);

TABLE NAME: Customer Subscriber Invoice
TABLE TECHNICAL NAME: Invoice
TABLE DESCRIPTION: Customer Invoices Status and other data
Model: Device Model
PaymentStatus: Paid (Invoice Paid), Due (Pending),
DueDate: Invoice Due date
CREATE TABLE Invoice (
InvoiceID INTEGER PRIMARY KEY,
SubscriberID INTEGER REFERENCES Subscriber(SubscriberID),
InvoiceDate DATE,
DueDate DATE,
TotalAmount REAL,
TaxAmount REAL,
DiscountAmount REAL,
PaymentStatus TEXT,
PaymentDate DATE,
PaymentMethod TEXT
);



**<span style="color:blue;">CREATE TABLE</span>** **<span style="color:blue;">\`Payment\`</span>** (  
  **<span style="color:blue;">\`PaymentID\`</span>** **<span style="color:blue;">INTEGER PRIMARY KEY</span>**,  
  **<span style="color:blue;">\`InvoiceID\`</span>** **<span style="color:blue;">INTEGER REFERENCES Invoice(InvoiceID)</span>**,  
  **<span style="color:blue;">\`PaymentDate\`</span>** **<span style="color:blue;">DATE</span>**,  
  **<span style="color:blue;">\`PaymentAmount\`</span>** **<span style="color:blue;">REAL</span>**,  
  **<span style="color:blue;">\`PaymentMethod\`</span>** **<span style="color:blue;">TEXT</span>**,  
  **<span style="color:blue;">\`TransactionID\`</span>** **<span style="color:blue;">TEXT</span>**,  
  **<span style="color:blue;">\`ConfirmationCode\`</span>** **<span style="color:blue;">TEXT</span>**,  
  **<span style="color:blue;">\`RefundStatus\`</span>** **<span style="color:blue;">TEXT</span>**,  
  **<span style="color:blue;">\`RefundDate\`</span>** **<span style="color:blue;">DATE</span>**,  
  **<span style="color:blue;">\`RefundAmount\`</span>** **<span style="color:blue;">REAL</span>**  
);





CREATE TABLE Activities (
ActivityID INTEGER PRIMARY KEY,
SubscriberID INTEGER REFERENCES Subscriber(SubscriberID),
ActivityType TEXT,
ActivityDate DATE,
ActivityDescription TEXT,
EmployeeID INTEGER,
Duration INTEGER,
Priority TEXT,
Resolution TEXT,
Feedback TEXT
);

CREATE TABLE Cases (
CaseID INTEGER PRIMARY KEY,
SubscriberID INTEGER REFERENCES Subscriber(SubscriberID),
CaseOpenDate DATE,
CaseCloseDate DATE,
CaseStatus TEXT,
AssignedEmployeeID INTEGER,
ResolutionTime INTEGER,
EscalationLevel TEXT,
CustomerFeedback TEXT,
ReopenedCount INTEGER
);

CREATE TABLE CaseNotes (
NoteID INTEGER PRIMARY KEY,
CaseID INTEGER REFERENCES Cases(CaseID),
NoteDate DATE,
NoteText TEXT,
AddedByEmployeeID INTEGER,
NoteType TEXT,
Attachment BLOB,
Visibility TEXT,
NotePriority TEXT,
FollowUpDate DATE
);

TABLE NAME: Telecommunication Outages
TABLE TECHNICAL NAME: Outages
TABLE DESCRIPTION: Outages happened in the customer area
cause: the cause of the outage
estimated_resolution: estimated resolution time
status: Resolved or Active
CREATE TABLE Outages (
OutageID INTEGER PRIMARY KEY,
SubscriberID INTEGER REFERENCES Subscriber(SubscriberID),
date_time DATETIME NOT NULL,
service VARCHAR(255) NOT NULL,
provider VARCHAR(255) NOT NULL,
location VARCHAR(255),
impact VARCHAR(255) NOT NULL,
cause VARCHAR(255),
reported_issues INT,
estimated_resolution VARCHAR(255),
updates TEXT,
status VARCHAR(255) NOT NULL
);'`

export const replacementRules = [
    { includes: 'Your name is “Elite personal helper”, you are a courteous customer service bot for “Elite Telco”, a Telecommunication company offering internet, cable and phone services to its consumers.', 
        replacement: initiationPrompt },
        {
            includes: 'you need to gather data regarding not paid customer invoices and if there is an active outages',
            replacement: invoiceDataRetrievalPrompt
        }, {
            includes :'SELECT i.InvoiceID, i.DueDate, i.TotalAmount, i.PaymentStatus, o.OutageID, o.date_time',
            replacement: generatedSQLQueryPrompt
        },
        {
            includes: `To gather all relevant data regarding the customer's general information, devices, and subscriptions based on the given database schema and the information provided in the conversation, you would need to execute a comprehensive SQL query that joins several tables.`,
            replacement: customerDataSQLQueryPrompt,
        },
        {
            includes: `please reflect on the customer's issue and the data you have, also take into consideration the steps above`,
            replacement: reflectionPrompt
        }
  ];