import React from 'react';
import { Textarea } from './textarea'; // Adjust the import path as necessary

export interface TextareaWithButtonProps
    extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    buttonText: string;
    onButtonClick: () => void;
}

const TextareaWithButton: React.FC<TextareaWithButtonProps> = ({
    buttonText,
    onButtonClick,
    className,
    children,
    ...props
}) => {
    return (
        <div className="relative flex w-full">
            <Textarea className={`flex-1 ${className}`} {...props} />
            <div
                onClick={onButtonClick}
                className="absolute right-1 top-0 h-full p-2"
            >
                {children}
            </div>
        </div>
    );
};

export default TextareaWithButton;
