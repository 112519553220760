import React from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const MarkDownComp = ({ text }: { text: string }) => {
  return (
    <Markdown
      children={text}
      rehypePlugins={[rehypeRaw]}
      components={{
        // Handling paragraph elements to control spacing
        p: ({ node, children, ...props }) => (
          <p className="m-0 whitespace-pre-line mt-[7px] mb-[7px]" {...props}>
            {children}
          </p> // Tailwind class for margin, adjust as needed
        ),
        ol: ({ node, ...props }) => (
          <ol
            style={{
              paddingLeft: '10px',
              margin: 0,
              marginBlockStart: '0',
              marginBlockEnd: '0',
              paddingInlineStart: '0',
              marginBottom: '12px',
              marginTop: '12px'
            }}
            {...props}
          />
        ),
        // Custom renderer for unordered lists
        ul: ({ node, ...props }) => (
          <ul
            style={{
              paddingLeft: '10px',
              margin: 0,
              marginBottom: '13px',
              marginTop: '13px'
            }}
            {...props}
          />
        ),
        // Custom renderer for list items
        li: ({ node, ...props }) => (
          <li
            style={{
              margin: 0,
              marginBlockStart: '0',
              marginBlockEnd: '0',
              marginBottom: '10px',
              marginTop: '10px'
            }}
            {...props}
          />
        ),
        // Handling code blocks and inline code
        code: ({ node, className, children, ...props }) => {
          const isBlockCode = className?.includes('language-');
          const codeBaseClasses = 'break-words';
          const codeStyle = {
            tabSize: 4
          };

          if (!isBlockCode) {
            // Block code
            return (
              <code
                style={codeStyle}
                className={`${className} ${codeBaseClasses} p-1 rounded whitespace-pre-line overflow-hidden`}
                {...props}>
                {children}
              </code>
            );
          } else {
            // Inline code
            return (
              <pre className="break-word">
                <code
                  style={codeStyle}
                  className={`${className}  ${codeStyle} break-words whitespace-pre-wrap overflow-hidden`}
                  {...props}>
                  {children}
                </code>
              </pre>
            );
          }
        }
      }}
    />
  );
};

export default MarkDownComp;
