import React from 'react'
import { Loader2Icon, LucideLoader } from 'lucide-react'
const Loading = ({ message }: { message: string }) => {
    return (
        <div className='h-full w-full absolute opacity-70 z-50 top-0 left-0 flex justify-center rounded-sm items-center bg-slate-800 flex-col'>
            <Loader2Icon className='animate-spin w-[60px] h-[60px]' color='white' />
            <div className='font-bold text-3xl text-white'>{message}</div>
        </div>
    )
}

export default Loading